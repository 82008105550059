import { ExternalLinkIcon, RepeatIcon } from '@chakra-ui/icons';
import { Button, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const handleUpdate = () => {
  // 更新のロジックを実装する部分
  window.location.reload();

  // ボタンがクリックされたときに実行される関数です
};
export const ResultLink = () => {
  return (
    <>
      {/* <Button size="md" fontWeight="light" variant="link">
        <a
          href="https://www.jaaf.or.jp/files/competition/document/1830-3.pdf"
          target="_blank"
        >
          タイムテーブル
        </a>
        <ExternalLinkIcon />
      </Button>
      <br />
      <Button size="md" fontWeight="light" variant="link">
        <a
          href="https://www.jaaf.or.jp/jch/108/combined-events/"
          target="_blank"
        >
          大会公式HP
        </a>
        <ExternalLinkIcon />
      </Button> */}
      <br />
      <Button size="md" fontWeight="light" variant="link">
        <Link to={'/dec'}>男子10種競技</Link>
        <ExternalLinkIcon />
      </Button>
      <br />
      <Button size="md" fontWeight="light" variant="link">
        <Link to={'/hep'}> 女子7種競技</Link>
        <ExternalLinkIcon />
      </Button>
      <br />
      <Button size="md" fontWeight="light" variant="link">
        <Link to={'/'}> 得点計算サイト</Link>
        <ExternalLinkIcon />
      </Button>
      {/* <Button size="md" fontWeight="light" variant="link">
        <Link to={'/U20dec'}> U20男子10種競技</Link>
        <ExternalLinkIcon />
      </Button>
      <br />
      <Button size="md" fontWeight="light" variant="link">
        <Link to={'/U20hep'}> U20女子7種競技</Link>
        <ExternalLinkIcon />
      </Button> */}
      <br />
      <br />
      <br />
      <div>
        <Flex justify="space-between" mr={4}>
          <Text fontSize={'xs'}>
            選手の名前をタップすると
            <br />
            プロフィールと持ち記録が見れます
          </Text>
          <Button colorScheme="gray" onClick={handleUpdate} size="xs">
            更新
            <RepeatIcon />
          </Button>
        </Flex>
      </div>
    </>
  );
};
